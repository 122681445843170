export default {
    common:{
        nowSignin: 'Sign in now',
        nowSignUp: 'Sign up now',
      },
      about: {
        aboutTitle1: 'Support for Multiple Models',
        aboutDesc1: 'MintChat integrates multiple AI chat models, allowing users to experience different AI technologies and features with just one account, without any switching.',
        aboutTitle2: 'Flexible Billing System',
        aboutDesc2: 'The platform offers a flexible billing system through subscriptions and a points system, providing users with a variety of payment options that can be adjusted according to their usage needs.',
        aboutTitle3: 'Image and Music Generation',
        aboutDesc3: 'MintChat not only has chat capabilities but also features image and music generation, broadening its range of use cases.',
        aboutTitle4: 'Cross-Device Synchronization',
        aboutDesc4: 'MintChat can be seamlessly used across different devices, with all conversations automatically synchronized, significantly enhancing user experience.',
      },
      bestAppTwo: {
        line1: 'MintChat, your all-purpose AI assistant',
        line2: 'Give yourself a smarter brain.',
        line3: 'MintChat supports ChatGPT3.5/4o/4 Turbo, Midjourney, Claude, Gemini, Suno, and many other large models. It also features our original AI face swapping, image-to-image generation, text-to-image generation, mind map generation, PDF document analysis, and GPTS application market to support your learning and life.',
      },
      featureTwo: {
        line1: 'MintChat AI, your intelligent assistant',
        line2: 'Unleash the power of a brain cluster and spark creativity. Your intelligent assistant is always there for you.',
        sinFeaturesTitle1: 'Support for Multiple Models',
        sinFeaturesDesc1: 'MintChat integrates multiple AI chat models, allowing users to experience different AI technologies and features with just one account, without any switching.',
        sinFeaturesTitle2: 'Flexible Billing System',
        sinFeaturesDesc2: 'The platform offers a flexible billing system through subscriptions and a points system, providing users with a variety of payment options that can be adjusted according to their usage needs.',
        sinFeaturesTitle3: 'Image and Music Generation',
        sinFeaturesDesc3: 'MintChat not only has chat capabilities but also features image and music generation, broadening its range of use cases.',
        sinFeaturesTitle4: 'Cross-Device Synchronization',
        sinFeaturesDesc4: 'MintChat can be seamlessly used across different devices, with all conversations automatically synchronized, significantly enhancing user experience.',
        sinFeaturesTitle5: 'Easy Operation',
        sinFeaturesDesc5: 'Ask a question with one click, and get the answer instantly.',
        sinFeaturesTitle6: 'Work and Study Assistant',
        sinFeaturesDesc6: 'Always ready to draft, rewrite, or improve your text content, even polish and check your papers.',
        sinFeaturesTitle7: 'All-in-One PDF Tool',
        sinFeaturesDesc7: 'Beyond AI, MintChat can also help you parse, convert, scan, and edit PDF files.',
      },
      heroBanner: {
        line1: 'MintChat AI, your one-stop AI assistant platform',
        line2: 'Integrating ChatGPT / Midjourney / Claude / Gemini / Suno and other large AI models, effortlessly empowering your work and life',
      },
      screenshot: {
        line1: 'Starting with AI, but not limited to AI.',
        line2: 'Instant access, just a touch away <br> Efficiency tools powered by MintChat.',
      },
      testimonialTwo: {
        name1: 'Harry Ken',
        designation1: 'System Architect, Software Developer',
        desc1: 'Very fast, the free version of the application features is already very useful, the interface is simple and the guidance is sufficient, the learning curve is very gentle.',
        name2: 'Smith Lavaren',
        designation2: 'University Professor, Academic Researcher',
        desc2: 'A highly efficient tool. Moreover, since the large models cover a wide range of subjects, you can seek help with almost any question, making it a tool that can continuously and rapidly expand your knowledge boundaries.',
        name3: 'Yamato Saito',
        designation3: 'Company Employee',
        desc3: 'MintChat is awesome! As an AI assistant, it always responds to my questions in a timely manner, explains clearly, and provides useful information. Its vast knowledge and attention to detail make my interactions with it very enjoyable.',
      },
      videoTwo: {
        line1: 'MintChat, your all-purpose AI assistant, answers complex questions, writes emails, reads articles, and performs intelligent searches. Available anywhere.',
        line2: 'MintChat integrates multiple AI chat models, allowing users to experience different AI technologies and features on one platform.',
      },
      price: {
        line1: 'Subscription',
        line2: 'Enhance your work efficiency with MintChat. We offer one of the most cost-effective solutions on the market!'
      },
      contact: {
        line1: 'Contact Us',
      },
      footer: {
        line1: 'Unlock Your True Potential',
        line2: 'Get MintChat Now',
        backtotop:'Back to Top',
      },
      navbar: {
        menu1: 'Home',
        menu2: 'About',
        menu3: 'Products',
        menu4: 'Performance',
        menu5: 'Support',
        menu6: 'Company',
      },
      company: {
        title: 'Title',
        specific: 'Specific',
      }
  
  }
  