import { createI18n } from 'vue-i18n'
import enUS from './en-US'
import zhCN from './zh-CN'
import zhTW from './zh-TW'
import jaJP from './ja-JP'

const defaultLocale = 'ja-JP'

const i18n = createI18n({
  locale: defaultLocale,
  legacy: false,
  fallbackLocale: 'zh-CN',
  allowComposition: true,
  messages: {
    'en-US': enUS,
    'zh-CN': zhCN,
    'zh-TW': zhTW,
    'ja-JP': jaJP,
  },
})

export const t = i18n.global.t

export function setupI18n(app) {
  app.use(i18n)
}

export default i18n
