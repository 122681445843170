export default {
  common:{
    nowSignin:'立即登录',
    nowSignUp:'现在注册',
  },
  about: {
    aboutTitle1: '多模型聚合支持',
    aboutDesc1:'MintChat 能够集成多个 AI 聊天模型，让用户只需一个账号，无需任何切换，即可体验不同的 AI 技术和特色。',
    aboutTitle2: '弹性计费系统',
    aboutDesc2:'平台通过订阅和 积分制的弹性计费系统，提供了灵活的支付方式，用户可以根据自己的使用需求进行选择以及资费调节。',
    aboutTitle3: '图像与音乐生成',
    aboutDesc3:'MintChat 不仅具备聊天对话能力，还具备了图像与音乐生成的功能，拓宽了使用场景。',
    aboutTitle4: '跨设备同步',
    aboutDesc4:'MintChat可以在不同设备间无缝切换使用，所有对话都会自动同步，有效提高了用户体验。',
  },
  bestAppTwo: {
    line1:'MintChat，你的全能 AI 助手',
    line2:'给自己一个更聪明的大脑。',
    line3:'MintChat 支持 ChatGPT3.5/4o/4 Turbo,Midjourney,Claude,Gemini,Suno,文心一言等众多大模型，同时还有我们独创的AI换脸，以图生图，以文生图，思维导图生成，PDF文档分析，GPTS应用市场等功能，助力你的学习与生活。',
  },
  featureTwo: {
    line1:'MintChat AI,你的智能助理',
    line2:'开启大脑集群，激发思维火花。你的智能助理，一直都在。',
    sinFeaturesTitle1:'多模型聚合支持',
    sinFeaturesDesc1:'MintChat 能够集成多个 AI 聊天模型，让用户只需一个账号，无需任何切换，即可体验不同的 AI 技术和特色。',
    sinFeaturesTitle2:'弹性计费系统',
    sinFeaturesDesc2:'平台通过订阅和积分制的弹性计费系统，提供了灵活的支付方式，用户可以根据自己的使用需求进行选择以及资费调节。',
    sinFeaturesTitle3:'图像与音乐生成',
    sinFeaturesDesc3:'MintChat 不仅具备聊天对话能力，还具备了图像与音乐生成的功能，拓宽了使用场景。',
    sinFeaturesTitle4:'跨设备同步',
    sinFeaturesDesc4:'MintChat可以在不同设备间无缝切换使用，所有对话都会自动同步，有效提高了用户体验。',
    sinFeaturesTitle5:'简易操作',
    sinFeaturesDesc5:'一键提出问题，顷刻之间，答案即现。',
    sinFeaturesTitle6:'工作与学习助手',
    sinFeaturesDesc6:'随时帮您起草、重写或改进文本内容，甚至可以帮您的论文润色查修。',
    sinFeaturesTitle7:'PDF万能工具',
    sinFeaturesDesc7:'不止于AI，MintChat还可以帮您的PDF进行文件进行解析，格式转换，扫描，编辑等操作。',
  },
  heroBanner:{
    line1:'MintChat AI，你的一站式AI助手平台',
    line2:'聚合ChatGPT / Midjourney / Claude / Gemini / Suno 等AI大模型，轻松赋能工作与生活'
  },
  screenshot:{
    line1:'始于 AI，但不止于 AI。',
    line2:'呼之即来，触之即得 <br>由 MintChat 驱动的效率工具。',
  },
  testimonialTwo: {
    name1:'Harry Ken',
    designation1:'系统架构师、软件开发者',
    desc1:'速度很快，會使用到的應用功能免費版本就已經很好用了，介面簡潔引導足夠，學習曲線很緩和。',
    name2:'Smith Lavaren',
    designation2:'大学教授，学术研究者',
    desc2:'效率非常高的利器。除此之外，因为大模型涵盖的学科范围非常广，所以几乎任何问题都可寻求帮助，也是一种可以不断快速扩展自己知识边界的工具。',
    name3:'Yamato Saito',
    designation3:'公司职员',
    desc3:'MintChat真是太棒了！作为一个AI助手，她总是及时回复我的问题，解释清楚并提供有用的信息。她的知识渊博而且细心周到，让我在与她的交流中感到非常愉快。',
  },
  videoTwo: {
    line1:'MintChat，你的 AI 全能助手，回答复杂问题，撰写邮件，阅读文章，智能搜索。随处可用。',
    line2:'MintChat 能够集成多个 AI 聊天模型，让用户在一个平台上体验不同的 AI 技术和特色。',
  },
  price: {
    line1:'订阅',
    line2:'使用MintChat提高工作效率我们提供市场上最具成本效益的解决方案之一！'
  },
  contact: {
    line1:'联系我们',
  },
  footer: {
    line1:'发挥您的真正潜力',
    line2:'现在就获取MintChat',
    backtotop:'回到顶部',
  },
  navbar: {
    menu1:'首页',
    menu2:'关于',
    menu3:'产品',
    menu4:'性能',
    menu5:'支持',
    menu6:'公司',
  },
  company: {
    title: '标题',
    specific: '特定商取引法',
  }
}
