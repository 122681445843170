import { createApp } from "vue";
import App from "./App.vue";
import router from './router'
import { setupI18n } from './locales'


import "swiper/css";
import './assets/css/font-awesome.min.css'
import './assets/css/pe-icon-7-stroke.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/style.scss'

const app = createApp(App)

app.use(router)
setupI18n(app)
app.mount("#app");
