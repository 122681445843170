import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  
    {
        path: '/',
        name: 'HomeTwo',
        component: () => import(/* webpackChunkName: "home-two" */ '../views/HomeTwo.vue')
    },
    {
        path: '/company',
        name: 'Company',
        component: () => import(/* webpackChunkName: "company" */ '../views/CompanyView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});
  
export default router;
