export default {
    common:{
        nowSignin: '今すぐサインイン',
        nowSignUp: '今すぐ登録',
      },
      about: {
        aboutTitle1: '複数モデルのサポート',
        aboutDesc1: 'MintChatは複数のAIチャットモデルを統合し、ユーザーは一つのアカウントで、切り替えなしで異なるAI技術と特徴を体験できます。',
        aboutTitle2: '柔軟な課金システム',
        aboutDesc2: 'プラットフォームはサブスクリプションとポイント制の柔軟な課金システムを提供し、ユーザーは使用ニーズに応じて支払い方法を選択し、料金を調整できます。',
        aboutTitle3: '画像と音楽の生成',
        aboutDesc3: 'MintChatはチャット機能だけでなく、画像と音楽の生成機能も備えており、使用シーンを拡大します。',
        aboutTitle4: 'デバイス間の同期',
        aboutDesc4: 'MintChatは異なるデバイス間でシームレスに使用でき、すべての会話が自動的に同期され、ユーザー体験を大幅に向上させます。',
      },
      bestAppTwo: {
        line1: 'MintChat、あなたの万能AIアシスタント',
        line2: '自分にもっと賢い脳を与えましょう。',
        line3: 'MintChatはChatGPT3.5/4o/4 Turbo, Midjourney, Claude, Gemini, Suno, 文心一言など多数の大規模モデルをサポートし、さらに独自のAI顔交換、画像から画像生成、テキストから画像生成、マインドマップ生成、PDFドキュメント解析、GPTSアプリ市場などの機能も備え、学習と生活を支援します。',
      },
      featureTwo: {
        line1: 'MintChat AI、あなたの知能アシスタント',
        line2: '脳のクラスターを開放し、創造力を引き出します。あなたの知能アシスタントは常にそばにいます。',
        sinFeaturesTitle1: '複数モデルのサポート',
        sinFeaturesDesc1: 'MintChatは複数のAIチャットモデルを統合し、ユーザーは一つのアカウントで、切り替えなしで異なるAI技術と特徴を体験できます。',
        sinFeaturesTitle2: '柔軟な課金システム',
        sinFeaturesDesc2: 'プラットフォームはサブスクリプションとポイント制の柔軟な課金システムを提供し、ユーザーは使用ニーズに応じて支払い方法を選択し、料金を調整できます。',
        sinFeaturesTitle3: '画像と音楽の生成',
        sinFeaturesDesc3: 'MintChatはチャット機能だけでなく、画像と音楽の生成機能も備えており、使用シーンを拡大します。',
        sinFeaturesTitle4: 'デバイス間の同期',
        sinFeaturesDesc4: 'MintChatは異なるデバイス間でシームレスに使用でき、すべての会話が自動的に同期され、ユーザー体験を大幅に向上させます。',
        sinFeaturesTitle5: '簡単操作',
        sinFeaturesDesc5: 'ワンクリックで質問し、すぐに回答が得られます。',
        sinFeaturesTitle6: '仕事と学習のアシスタント',
        sinFeaturesDesc6: 'いつでも文章の起草、書き直し、改善を手助けし、論文のチェックや修正も行います。',
        sinFeaturesTitle7: 'PDF万能ツール',
        sinFeaturesDesc7: 'AIだけでなく、MintChatはPDFファイルの解析、変換、スキャン、編集もサポートします。',
      },
      heroBanner: {
        line1: 'MintChat AI、一体化したAIアシスタントプラットフォーム',
        line2: 'ChatGPT / Midjourney / Claude / Gemini / Sunoなどの大規模AIモデルを統合し、仕事と生活を楽にします。',
      },
      screenshot: {
        line1: 'AIから始まり、AIで終わらない。',
        line2: '呼べばすぐに、タッチで利用 <br> MintChat駆動の効率ツール。',
      },
      testimonialTwo: {
        name1: 'Harry Ken',
        designation1: 'システムアーキテクト、ソフトウェア開発者',
        desc1: '非常に速いです。無料版のアプリ機能も既に非常に役立ちます。インターフェースはシンプルでガイダンスが十分です。学習曲線は非常に緩やかです。',
        name2: 'Smith Lavaren',
        designation2: '大学教授、学術研究者',
        desc2: '非常に効率的なツールです。さらに、大規模なモデルが幅広い科目をカバーしているので、ほぼすべての質問に対応でき、知識の境界を迅速に広げることができるツールです。',
        name3: 'Yamato Saito',
        designation3: '会社員',
        desc3: 'MintChatは素晴らしいです！AIアシスタントとして、いつも私の質問に迅速に回答し、明確に説明し、有用な情報を提供してくれます。その広範な知識と細部への配慮は、やり取りを非常に楽しくしてくれます。',
      },
      videoTwo: {
        line1: 'MintChat、あなたの万能AIアシスタント。複雑な質問に答え、メールを書き、記事を読み、インテリジェント検索を行います。どこでも利用可能。',
        line2: 'MintChatは複数のAIチャットモデルを統合し、ユーザーが一つのプラットフォームで異なるAI技術と特徴を体験できるようにします。',
      },
      price: {
        line1:'サブスクリプション',
        line2:'MintChatを使用して仕事の効率を高めましょう。私たちは市場で最もコスト効率の高いソリューションの一つを提供しています！'
      },
      contact: {
        line1:'お問い合わせ',
      },
      footer: {
        line1:'あなたの本当の可能性を引き出す',
        line2:'今すぐMintChatを手に入れよう',
        backtotop:'トップに戻る',
      },
      navbar: {
        menu1:'ホーム',
        menu2:'概要',
        menu3:'製品',
        menu4:'パフォーマンス',
        menu5:'サポート',
        menu6:'特定商取引法',
      },
  }
  