export default {
  common:{
    nowSignin:'立即登入',
    nowSignUp:'現在註冊',
  },
  about: {
    aboutTitle1: '多模型聚合支援',
    aboutDesc1:'MintChat 能夠集成多個 AI 聊天模型，讓用戶隻需一個賬號，無需任何切換，即可體驗不同的 AI 技術和特色。',
    aboutTitle2: '彈性計費係統',
    aboutDesc2:'平臺通過訂閱和 積分製的彈性計費係統，提供了靈活的支付方式，用戶可以根據自己的使用需求進行選擇以及資費調節。',
    aboutTitle3: '圖像與音樂生成',
    aboutDesc3:'MintChat 不僅具備聊天對話能力，還具備了圖像與音樂生成的功能，拓寬了使用場景。',
    aboutTitle4: '跨設備同步',
    aboutDesc4:'MintChat可以在不同設備間無縫切換使用，所有對話都會自動同步，有效提高了用戶體驗。',
  },
  bestAppTwo: {
    line1:'MintChat，你的全能 AI 助手',
    line2:'給自己一個更聰明的大腦。',
    line3:'MintChat 支援 ChatGPT3.5/4o/4 Turbo,Midjourney,Claude,Gemini,Suno,文心一言等衆多大模型，同時還有我們獨創的AI換臉，以圖生圖，以文生圖，思維導圖生成，PDF文檔分析，GPTS應用市場等功能，助力你的學習與生活。',
  },
  featureTwo: {
    line1:'MintChat AI,你的智能助理',
    line2:'開啓大腦集群，激發思維火花。你的智能助理，一直都在。',
    sinFeaturesTitle1:'多模型聚合支援',
    sinFeaturesDesc1:'MintChat 能夠集成多個 AI 聊天模型，讓用戶隻需一個賬號，無需任何切換，即可體驗不同的 AI 技術和特色。',
    sinFeaturesTitle2:'彈性計費係統',
    sinFeaturesDesc2:'平臺通過訂閱和積分製的彈性計費係統，提供了靈活的支付方式，用戶可以根據自己的使用需求進行選擇以及資費調節。',
    sinFeaturesTitle3:'圖像與音樂生成',
    sinFeaturesDesc3:'MintChat 不僅具備聊天對話能力，還具備了圖像與音樂生成的功能，拓寬了使用場景。',
    sinFeaturesTitle4:'跨設備同步',
    sinFeaturesDesc4:'MintChat可以在不同設備間無縫切換使用，所有對話都會自動同步，有效提高了用戶體驗。',
    sinFeaturesTitle5:'簡易操作',
    sinFeaturesDesc5:'一鍵提出問題，頃刻之間，答案即現。',
    sinFeaturesTitle6:'工作與學習助手',
    sinFeaturesDesc6:'隨時幫您起草、重寫或改進文本內容，甚至可以幫您的論文潤色查修。',
    sinFeaturesTitle7:'PDF萬能工具',
    sinFeaturesDesc7:'不止於AI，MintChat還可以幫您的PDF進行文件進行解析，格式轉換，掃描，編輯等操作。',
  },
  heroBanner:{
    line1:'MintChat AI，你的一站式AI助手平臺',
    line2:'聚合ChatGPT / Midjourney / Claude / Gemini / Suno 等AI大模型，輕鬆賦能工作與生活'
  },
  screenshot:{
    line1:'始於 AI，但不止於 AI。',
    line2:'呼之即來，觸之即得 <br>由 MintChat 驅動的效率工具。',
  },
  testimonialTwo: {
    name1:'Harry Ken',
    designation1:'係統架構師、軟體開發者',
    desc1:'速度很快，會使用到的應用功能免費版本就已經很好用了，介麵簡潔引導足夠，學習麯線很緩和。',
    name2:'Smith Lavaren',
    designation2:'大學教授，學術研究者',
    desc2:'效率非常高的利器。除此之外，因為大模型涵蓋的學科範圍非常廣，所以幾乎任何問題都可尋求幫助，也是一種可以不斷快速擴展自己知識邊界的工具。',
    name3:'Yamato Saito',
    designation3:'公司職員',
    desc3:'MintChat真是太棒了！作為一個AI助手，她總是及時回複我的問題，解釋清楚並提供有用的信息。她的知識淵博而且細心周到，讓我在與她的交流中感到非常愉快。',
  },
  videoTwo: {
    line1:'MintChat，你的 AI 全能助手，回答複雜問題，撰寫郵件，閱讀文章，智能搜索。隨處可用。',
    line2:'MintChat 能夠集成多個 AI 聊天模型，讓用戶在一個平臺上體驗不同的 AI 技術和特色。',
  },
  price: {
    line1:'訂閱',
    line2:'使用MintChat提高工作效率我們提供市場上最具成本效益的解決方案之一！'
  },
  contact: {
    line1:'聯係我們',
  },
  footer: {
    line1:'發揮您的真正潛力',
    line2:'現在就獲取MintChat',
    backtotop:'回到頂部',
  },
  navbar: {
    menu1:'首頁',
    menu2:'關於',
    menu3:'産品',
    menu4:'性能',
    menu5:'支援',
    menu6:'公司',
  },
  company: {
    title: '標題',
    specific: '特定商取引法',
  }
}
